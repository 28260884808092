import React from "react"
import PropTypes from "prop-types"
import LeftPanel from "./LeftPanel.js"
class Header extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      user: Number(this.props.logged_in) === 1 ? JSON.parse(this.props.user) : null,
      session: Number(this.props.logged_in) === 1 ? JSON.parse(this.props.session) : null,
      company: Number(this.props.logged_in) === 1 ? JSON.parse(this.props.company) : null,
      notifications: [1,2,3],
      show_user_window: false,
      show_left_panel: Number(this.props.logged_in) === 1,
      show_notifications: false
    };

    this.MAIN_COLOUR = '#040dba';
    this.SECONDARY_COLOUR = '#32bfb8';//'#26d463';

    this.handle_click = this.handle_click.bind(this);

  }

  componentDidMount(){
    window.addEventListener('click', this.handle_click);
    if (this.state.show_left_panel){
      document.getElementById('content').style.marginLeft = '240px';
      document.getElementById('footer').style.marginLeft = '240px';
      let x = document.getElementById('flash-wrapper');
      if (x != null && x != null){
        x.style.marginLeft = '210px';
      }
    }
  }

  handle_click(event){
    //console.log(e.target.id);
    let el = event.target;
    /*if ((!el.className.includes('user-window') && el.id !== 'user-icon' && el.id !== 'noti-icon') || el.id === 'user-window-x'){
      this.setState({
        show_user_window: false
      });
    }*/
    /*if ((document.getElementById('user-window') !== null && !document.getElementById('user-window').contains(el) && el.id !== 'user-icon' && el.id !== 'noti-icon') || el.id === 'user-window-x'){
      this.setState({
        show_user_window: false
      });
    }*/
    const Sa = this.state.show_user_window && this.state.show_notifications;
    const Sb = this.state.show_user_window && !this.state.show_notifications;
    const So = !this.state.show_user_window;
    const a = (el.id === 'noti-icon');
    const b = (el.id === 'user-icon');
    const c = (el.id === 'user-window-x');
    const d = (document.getElementById('user-window') !== null && document.getElementById('user-window').contains(el));
    const e = !a && !b && !c && !d;
    //console.log('click! a = '+String(a)+', b = '+String(b)+', c = '+String(c)+', d = '+String(d)+', e = '+String(e));
    if ((So && a) || (Sb && a)){
      // open a
      this.setState({
        show_user_window: true,
        show_notifications: true
      });
    } else if ((So && b) || (Sa && b)){
      // open b
      this.setState({
        show_user_window: true,
        show_notifications: false
      });
    } else if ((Sa && (a || c || e)) || (Sb && (b || c || e))){
      this.setState({
        show_user_window: false
      });
    }
  }

  /*log_out(){
    // /sessions/destroy
    fetch("/sessions/destroy", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      }
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
      if (json.success){
        window.location.href = "/";
      }
    });
  }*/

  render () {
    return (
      <div style={{margin: '-8px'}}>
        <div style={{backgroundColor: this.state.show_left_panel ? null : this.MAIN_COLOUR, color: '#fff', padding: '12px', height: '34px', zIndex: '90', position: 'fixed', top: '0px', left: '0px', width: '100%'}}>
          <table style={{width: '95%'}}><tbody>
            <tr>
              <td style={{width: '170px'}}>
                <div style={{fontSize: '28px', fontWeight: 'bold', cursor: 'pointer'}} onClick={() => location.href = '/'}>
                  <span style={{color: this.SECONDARY_COLOUR}}>Pay</span><span>Solve</span>
                </div>
              </td>
              <td>

              </td>
              <td>
                {this.state.user === null ? 
                <div style={{textAlign: 'right'}}>
                  <button onClick={() => location.href = '/login'} className="header-button">
                    <table><tbody>
                      <tr>
                        <td style={{fontSize: '14px', paddingRight: '4px'}}>
                          <i className="fa-solid fa-right-to-bracket"></i>
                        </td>
                        <td>
                          Login
                        </td>
                      </tr>
                    </tbody></table>
                  </button>
                  <button onClick={() => location.href = '/company/new'} className="header-button">
                    <table><tbody>
                        <tr>
                          <td style={{fontSize: '14px', paddingRight: '4px'}}>
                            <i className="fa-solid fa-user-plus"></i>
                          </td>
                          <td>
                            Signup
                          </td>
                        </tr>
                      </tbody></table>
                  </button>
                </div> : 
                <table style={{marginLeft: 'auto', marginRight: '0px'}}><tbody>
                  <tr>
                    <td>
                  <div onClick={() => this.setState({})} id="noti-icon" style={{width: '30px', height: '30px', borderRadius: '15px', backgroundColor: '#c9c9c9', cursor: 'pointer', marginLeft: 'auto', marginRight: '0px'}}>
                    {this.state.notifications.length > 0 ? 
                    <div style={{display: 'block', minWidth: this.state.notifications.length > 9 ? '12px' : '8px', paddingLeft: '2px', paddingRight: '2px', height: '12px', backgroundColor: 'red', borderRadius: '6px', /*marginLeft: 'auto', marginRight: '0px',*/ marginLeft: '18px', color: '#fff', fontSize: '10px', textAlign: 'center', paddingTop: '0px'}}>
                      {this.state.notifications.length > 9 ? '9+' : this.state.notifications.length}
                    </div> : null}
                  </div>
                  
                    </td><td>
                  <div onClick={() => this.setState({})} id="user-icon" style={{width: '30px', height: '30px', borderRadius: '15px', backgroundColor: '#c9c9c9', cursor: 'pointer', marginLeft: 'auto', marginRight: '0px'}}>

                  </div>
                    </td>
                  </tr>
                </tbody></table>}
              </td>
            </tr>
          </tbody></table>
        </div>
        {/*<div style={{backgroundColor: "#efefef", padding: '10px', fontFamily: 'Roboto Mono'}}>
          <div>
            User: {JSON.stringify(this.state.user)}
          </div>
          <div>
            Session: {JSON.stringify(this.state.session)}
          </div>
          <div>
            Company: {JSON.stringify(this.state.company)}
          </div>
          <div>
            JS Unix time: {Math.floor(Date.now()/1000)}
          </div>
        </div>*/}

        {this.state.show_user_window ? 
          <div id="user-window" className="user-window" style={{width: '180px', height: '240px', padding: '15px', backgroundColor: '#fff', border: 'solid', borderWidth: '1px', borderColor: '#c9c9c9', position: 'fixed', top: '70px', right: '30px', borderRadius: '6px'}}>
            {this.state.show_notifications ? 
            <div>
              Notifications
            </div> : 
            <div>
              <div style={{textAlign: 'right'}}>
                <span style={{cursor: 'pointer'}} id="user-window-x">
                  x
                </span>
              </div>
              <div style={{fontSize: '12px'}}>
                Logged in as <b>{this.state.user.name}</b> since <b>{unix2iso8601(this.state.session.created_at)}</b>
              </div>
              <div style={{textAlign: 'center', marginTop: '10px'}}>
                <div style={{color: 'grey'}}>
                  Session ID:
                </div>
                <div className="code">
                  {this.state.session.code}
                </div>
                <form acceptCharset="UTF-8" action="/sessions/destroy" method="post">
                  <input name="authenticity_token" type="hidden" value={this.props.auth_token} />
                  <button>Log Out</button>
                </form>
              </div>
              {/*<div className="user-window" style={{fontWeight: 'bold'}}>{this.state.user === undefined || this.state.user === null ? null : this.state.user.name}</div>*/}
            </div>}
          </div> : null}

          {this.state.show_left_panel ? 
            <div style={{position: 'fixed', top: '0px', left: '0px', padding: '5px', backgroundColor: this.MAIN_COLOUR/*'#1b1b1b'*/, height: '100%', width: '210px', zIndex: '91'}}>
              <div style={{fontSize: '26px', fontWeight: 'bold', cursor: 'pointer', color: '#fff', textAlign: 'center', marginTop: '10px'}} onClick={() => location.href = '/'}>
                <span style={{color: this.SECONDARY_COLOUR}}>Pay</span><span>Solve</span>
              </div>
              <LeftPanel />
            </div> : null}
      </div>
    );
  }
}

function unix2iso8601(unix){
  let date = new Date(unix * 1000);
  return date.toLocaleString();
}

export default Header
