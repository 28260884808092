import React from "react"
import PropTypes from "prop-types"
import DashboardInvoicesGraph from "./DashboardInvoicesGraph.js";

class DashboardMerchant extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      matched_transfer_amounts: null,
      invoice_values: null,
      invoices_issued: null,
      invoices_issued_paid: null,
      invoices_outstanding: null,
      num_days: 7,
      available_days: [3,7,14,30]
    };

    this.get_data = this.get_data.bind(this);
    this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);
    this.set_day_period = this.set_day_period.bind(this);
    this.abbrev_num = this.abbrev_num.bind(this);

  }

  componentDidMount(){
    this.get_data();
    document.getElementById('time-period-dashboard-merchant').value = 1;
  }

  get_data(){
    const created_at_sql = "created_at < '"+(new Date()).toISOString().split('T').join(' ').split('.')[0]+"' AND created_at > '"+(new Date(Number(new Date()) - 86400000*7)).toISOString().split('T').join(' ').split('.')[0]+"'";
    let queries = [
      {
        objname: 'Transfer',
        name: 'matched_transfer_amounts',
        statement: created_at_sql+" AND status in (2,3,4)",
        function: 'sum',
        columns: ['amount']
      },
      {
        objname: 'Invoice',
        name: 'invoice_values',
        statement: created_at_sql,
        function: 'sum',
        columns: ['amount']
      },
      {
        objname: 'Invoice',
        name: 'invoices_issued',
        statement: created_at_sql,
        function: 'count',
        columns: ['amount']
      },
      {
        objname: 'Invoice',
        name: 'invoices_outstanding',
        statement: 'status in (1)',
        function: 'count',
        columns: ['amount']
      }
    ];
    fetch("/api/stats", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({queries: queries})
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      /*let new_state = {};
      queries.forEach((q) => {
        new_state[q['name']] = queries[q['name']]
      });
      this.setState(new_state);*/
      this.setState({
        matched_transfer_amounts: json.queries['matched_transfer_amounts'],
        invoice_values: json.queries['invoice_values'],
        invoices_issued: json.queries['invoices_issued'],
        invoices_outstanding: json.queries['invoices_outstanding']
      });
    });
  }

  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }

  set_day_period(d){
    this.setState({
      num_days: d
    }, () => {
      this.get_data();
    });
  }

  abbrev_num(x){
    if (x < 1000){
      return x;
    } else if (x < 100000){
      return String(x / 1000.0).split('.').map((v,i) => [v,(x % 1000 === 0 ? '' : v[0])][i]).join('.') + 'K';
    } else if (x < 1000000){
      return String(x / 1000.0).split('.')[0] + 'K';
    } else if (x < 100000000){
      return String(x / 1000000.0).split('.').map((v,i) => [v,(x % 1000000 === 0 ? '' : v[0])][i]).join('.') + 'M';
    } else {
      return String(x / 1000000.0).split('.')[0] + 'M';
    }
  }

  render () {
    console.log(this.state);
    return (
      <div style={{maxWidth: '880px', margin: 'auto'}}>
      <div style={{display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'start', alignItems: 'start'}}>
        <div style={{width: '540px'}}>
          <div style={{display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'start', alignItems: 'start'}}>
            <div className="form-section" style={{width: '128px'}}>
              <h4 style={{textAlign: 'center', color: 'grey'}}>
                Invoices Paid
              </h4>
              <h2 style={{textAlign: 'center'}}>
                {this.state.matched_transfer_amounts === null ? '-' : '$'+this.abbrev_num(Math.floor(this.state.matched_transfer_amounts / 100.0))}
              </h2>
            </div>
            <div className="form-section" style={{width: '128px'}}>
              <h4 style={{textAlign: 'center', color: 'grey'}}>
                Invoices Issued
              </h4>
              <h2 style={{textAlign: 'center'}}>
                {this.state.invoice_values === null ? '-' : '$'+this.abbrev_num(Math.floor(this.state.invoice_values / 100.0))}
              </h2>
            </div>
            <div className="form-section" style={{width: '128px'}}>
              <h4 style={{textAlign: 'center', color: 'grey'}}>
                Invoice Issued
              </h4>
              <h2 style={{textAlign: 'center'}}>
                {this.state.invoice_values === null ? '-' : '$'+this.abbrev_num(Math.floor(this.state.invoice_values / 100.0))}
              </h2>
            </div>

            <div className="form-section" style={{width: '520px', margin: '5px'}}>
              <h4 style={{textAlign: 'center'}}>
                Invoices past {this.state.num_days} days
              </h4>
              <DashboardInvoicesGraph auth_token={this.props.auth_token} num_days={this.state.num_days} />
            </div>
          </div>
        </div>

        <div className="form-section" style={{width: '280px'}}>
          <div style={{textAlign: 'center'}}>
            <h4 style={{color: 'grey', textAlign: 'center'}}>
              Time period
            </h4>
            <select className="general-select" id="time-period-dashboard-merchant" style={{width: '50%'}} onChange={(e) => this.set_day_period(this.state.available_days[e.target.value])}>
              {this.state.available_days.map(
                (d, index) =>
                <option key={index} value={index}>
                  {d} days
                </option>
              )}
            </select>
            <table className="dashboard-table"><tbody>
              <tr>
                <td>
                  <i className="fa-solid fa-file-invoice"></i>
                </td>
                <td>
                  Invoices issued
                  <p>
                    Total number
                  </p>
                </td>
                <td>
                  {this.state.invoices_issued === null ? '-' : this.abbrev_num(this.state.invoices_issued)}
                </td>
              </tr>
              <tr>
                <td>
                  <i className="fa-solid fa-bell"></i>
                </td>
                <td>
                  Outstanding
                  <p>
                    Unpaid
                  </p>
                </td>
                <td>
                  {this.state.invoices_outstanding === null ? '-' : this.abbrev_num(this.state.invoices_outstanding)}
                </td>
              </tr>
            </tbody></table>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default DashboardMerchant
